import React, { useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Layout from '../components/Layout/Layout';
import SEO from '../components/seo';

import styled from 'styled-components';
import BlockContent from '@sanity/block-content-to-react';
import colors from '../components/_colors.scss';

const Podcasts = styled.main`
	display: flex;
	flex-direction: column;

	@media (min-width: 1024px) {
		flex-direction: row;
		height: 100%;
	}
`;

const Section = styled.section`
	&.title {
		padding: 20px;
	}
	&.list {
		border-top: 1px solid;
	}
	@media (min-width: 1024px) {
		width: 50%;
		height: calc(100vh - 226px);
			overflow-y: scroll;
		&.title {
			padding: 30px 50px;
		}
		&.list {
			border-top: none;
			border-left: 1px solid;

		}
	}
`;

const PageTitle = styled.h2`
	font-size: 24px;

	@media (min-width: 1024px) {
		font-size: 2.6666666667em;
	}
`;

const PodcastInfo = styled.aside`
	margin-top: 20px;
	/* @media (min-width: 800px) {
		display: flex;
		align-items: flex-end;
	} */
`

const PodcastImage = styled.img`
	max-width: 100%;
	padding-bottom: 10px;
	@media (min-width: 1024px) {
		padding-bottom: 0px;
		width: 33.3%;
		max-width: 500px;
	}
`;

const SubscribeLink = styled.a`
	font-size: 16px;
	color: inherit;
	transition: all 200ms ease;
	display: block;

	padding: 5px;
	border: 1px solid;
	border-radius: 4px;
	width: max-content;
	text-decoration: none;

	margin-top: 20px;

	&:hover {
		background-color: #131313;
		color: #f9fdf7;
	}

	@media (min-width: 1024px) {

		font-size: 24px;
	}
`;

const PodcastDescription = styled.div`
	margin-top: 20px;
	p {
		margin-bottom: 1em;

		&::last-of-type {
			margin-bottom: 0;
		}
	}
`


const PodcastTitle = styled.h3`
	font-size: 18px;
	padding: 20px;

	a {
		color: inherit;
	}

	@media (min-width: 1024px) {
		font-size: 2em;
		padding: 30px 25px 20px;
	}
`;

const PodcastSubTitle = styled.p`
	padding: 0 20px;

	@media (min-width: 1024px) {
		padding: 0 25px;
	}
`;

const PressArticle = styled.article`
	border-bottom: 1px solid currentColor;
`;


const Podcast = styled.div`
	padding: 20px;
`;

const PodcastLink = styled(Link)`
	display: inline-block;
	font-size: 14px;
	padding: 0 0 20px 20px;
	color: inherit;
`

const PodcastPage = () => {
	const data = useStaticQuery(graphql`
	{
		sanityPodcastPage {
		image {
			asset {
			url
			fluid {
				base64
				aspectRatio
				src
				srcSet
				srcWebp
				srcSetWebp
				sizes
			}
			}
		}
		description: _rawDescription
		subscribeLink
		list {
			_key
			title
			subtitle
			podcastEmbed
			slug {
			current
			}
		}
		}
	}`)

	const { sanityPodcastPage} = data;

	const { image, description, list = [], subscribeLink} = sanityPodcastPage



	return (
		<React.Fragment>
			<SEO title='Podcasts | Marquise Stillwell' />

			<Podcasts>
				<Section className='title'>
					<PageTitle>Podcast</PageTitle>
					<PodcastInfo>
						<PodcastImage src={image.asset.url} />
						<SubscribeLink href={subscribeLink} target='_blank' rel='noreferrer noopener'>
							Subscribe
						</SubscribeLink>
					</PodcastInfo>
					<PodcastDescription>
						<BlockContent blocks={description} />
					</PodcastDescription>
				</Section>
				<Section className='list'>
					{list.length > 0 && (
						<React.Fragment>
							{list.map((pod, index) => {
								return (
									<PressArticle key={pod._key}>
										<PodcastTitle>
											<Link to={`/podcasts/${pod.slug.current}`}>{pod.title}</Link>
										</PodcastTitle>
										{pod.subtitle && <PodcastSubTitle>{pod.subtitle}</PodcastSubTitle>}
										<Podcast>
											<div
												dangerouslySetInnerHTML={{
													__html: pod.podcastEmbed,
												}}
											/>
										</Podcast>

										<PodcastLink to={`/podcasts/${pod.slug.current}`}>Click to learn more</PodcastLink>
									</PressArticle>
								)
							})}
						</React.Fragment>
					)}
				</Section>
			</Podcasts>
		</React.Fragment>
	)
};

export default PodcastPage;
